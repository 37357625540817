/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: Montserrat-Regular;
    src: url(/assets/fonts/Montserrat-Regular.otf);
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(/assets/fonts/Montserrat-Medium.otf);
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(/assets/fonts/Montserrat-Bold.otf);
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(/assets/fonts/Montserrat-SemiBold.otf);
}

html.ios,
html,
ion-app,
p,
span {
    font-family: Montserrat-Regular;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-title {
    font-family: Montserrat-Medium;
    // letter-spacing: 2px;
}

ion-text,
ion-note {
    font-family: Montserrat-Regular;
}


// ~~~~~~~~~~~. background color of the splash screen is #1c0442;

// .errp {
//     color: var(--ion-color-white);
//     font-size: 12px;
//     background-color: var(--ion-color-primary);
//     padding: 10px;
//     border-radius: 24px;
//     display: flex;
//     justify-content: center;
//     align-items: left;
//     width: 85%;
//     margin: 7px auto;
//     ion-icon{
//         font-size: 14px;
//         margin-right: 6px;
//         margin-top: 0px;
//         color: var(--ion-color-danger-radio) !important;
//     }
// }

.errp {
    color: red !important;
    background: #fdd8d8 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    font-size: 13px !important;
    line-height: 26.8px !important;
    letter-spacing: normal !important;
    text-transform: none !important;
    font-weight: normal !important;
    max-width: 88%;
    width: 100%;
    margin: auto !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    position: relative;
    bottom: 0;
    border: none !important;
    text-align: left;

    ion-icon {
        font-size: 20px !important;
        color: red !important;
        margin: 0px 5px 0px 0px !important;
        position: relative !important;
        top: 5px;
    }
}

ion-loading {
    .loading-wrapper {
        background: #fff !important;
        box-shadow: unset !important;

        .loading-content {
            color: var(--ion-color-primary) !important;
        }

        ion-spinner {
            color: var(--ion-color-primary) !important;
        }
    }
}

ion-action-sheet {
    --background: #fff !important;
    --button-background: #fff !important;
    --button-background-activated: #fff !important;
}

ion-alert {
    --background: #fff !important;

    .alert-button-group {
        button {
            border-top: 0.55px solid #ddd !important;
            border-right: 0.55px solid #ddd !important;
        }
    }
}

.purchasemodal {
    max-width: 90%;
    margin: auto;
    max-height: 58%;
    border-radius: 15px;
    box-shadow: 1px 6px 8px #9f9f9f;
    border: 1px solid #898989;
}

app-stocklisting {
    ion-text {
        p {
            font-size: 16px !important;
        }
    }

    ion-list {
        ion-item {
            img {
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.native-input.sc-ion-input-ios {
    padding: 5px !important;
}

span.stock_down,
span.stock_up {
    font-size: 13px;
    padding-left: 5px;
    font-weight: 500;
    color: red;
    width: 100%;
}

span.stock_up {
    color: #00b519;
}

app-home {
    ion-list {
        ion-item {
            img {
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0px;
}

.customToolBar {
    --background: var(--ion-color-white) !important;

    ion-button {
        --color: var(--ion-color-primary) !important;
    }

    ion-icon {
        color: var(--ion-color-primary) !important;
    }
}

ion-header {
    ion-toolbar {
        --background: var(--ion-color-white);
        --border-style: none;
        --border-width: 0px;

        ion-title {
            text-transform: capitalize;
        }

        ion-buttons {
            ion-button {
                box-shadow: 0px 0px 10px #00000014;
                --border-radius: 6px;
                border-radius: 6px;
            }

            ion-back-button {
                box-shadow: 0px 0px 10px #00000014;
                --border-radius: 6px;
                --background: var(--ion-color-white);
                --color: var(--ion-color-primary);
                border-radius: 6px;
                height: 32px;

                --padding-start: 5px;
                --padding-end: 5px;
            }
        }
    }
}

ion-footer {
    background: var(--ion-color-white);
}

ion-content {
    --background: var(--ion-color-white);
}

.outline-button {
    --background: transparent;
    --background-activated: transparent;
    --border-color: var(--ion-color-primary);
    --border-radius: 30px;
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: none;
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary);
    font-family: Montserrat-Regular;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    height: 52px;
}

.fill-button {
    --background: var(--ion-color-grd);
    --background-activated: var(--ion-color-grd);
    --background-focused: var(--ion-color-grd);
    --border-color: var(--ion-color-primary);
    --border-radius: 30px;
    --border-style: none;
    --border-width: 0px;
    --box-shadow: none;
    --color: var(--ion-color-white);
    --color-activated: var(--ion-color-white);
    font-family: Montserrat-Regular;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    height: 52px;
}

.field_item {
    --background: var(--ion-color-field-bg);
    --background-activated: var(--ion-color-field-bg);
    --background-activated-opacity: 1;
    --background-focused: var(--ion-color-field-bg);
    --background-hover: var(--ion-color-field-bg);
    --border-width: 0px;
    // --border-color:#805799;
    --color: var(--ion-color-label);
    --color-activated: var(--ion-color-label);
    --padding-start: 15px;
    --padding-end: 10px;
    font-family: Montserrat-Medium;
    font-size: 16px;
    --min-height: 52px;
    --border-radius: 30px;
}

.label {
    color: #1c1c1c;
    display: block;
    font-size: 16px;
    font-family: Montserrat-SemiBold;
    margin-bottom: 10px;
}

.bg-white {
    background: var(--ion-color-white);
    --background: var(--ion-color-white);
}

.font-medium {
    font-family: Montserrat-Medium !important;
}

.text-sm {
    font-size: 14px !important;
}

.text-md {
    font-size: 16px !important;
}

.text-lg {
    font-size: 18px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.m-0 {
    margin: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}


.p-l-r {
    --padding-start: 16px;
    --padding-end: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.title-border {
    position: relative;
    color: var(--ion-color-white);
    font-family: Montserrat-Bold;
    font-size: 18px;
    padding-bottom: 10px;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 50px;
        background: var(--ion-color-grd);
        border-radius: 5px;
    }
}

.sub-title {
    color: #7891a0;

    font-size: 16px;

    font-family: Montserrat-Regular;
}

.color-medium {
    color: #7891a0;
}

.my-custom-class {
    &::part(content) {
        width: 90%;
        height: 300px;
        background: transparent;
    }
}


.location-popup {
    &::part(content) {
        width: 90%;
        height: 300px;
        background: transparent;
        border-radius: 16px;
    }
}

.gallery_modal {
    &::part(content) {
        width: 90%;
        height: 400px;
        background: transparent;
        border-radius: 20px;
    }
}

// common search style
ion-popover {
    &::part(content) {
        border-radius: 25px;
    }
}

.search {
    --background: var(--ion-color-white);
    box-shadow: 0px 0px 10px #00000017;
    margin: 10px 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    --border-radius: 12px;
    border-radius: 12px;
    background: var(--ion-color-white);
    --padding-top: 2px;
    --padding-bottom: 2px;
    --inner-padding-end: 0px;

    img {
        box-shadow: 0px 0px 10px #00000017;
        border-radius: 12px;
        width: 40px;
    }

    ion-searchbar {
        --background: var(--ion-color-white);
        --border-radius: 0px;
        --box-shadow: none;
        --icon-color: var(--ion-color-primary);
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

// End

.icon-style {
    box-shadow: 0px 0px 10px #00000014;
    border-radius: 6px;
    font-size: 24px !important;
    margin: 5px;
    padding: 5px;
    background: var(--ion-color-white);
}

ngx-star-rating {
    display: flex;
    align-items: center;
    justify-content: center;

    form {

        label.star {
            color: #DDF9FA !important;
        }
    }
}


.alert-title.sc-ion-alert-ios {
    margin-top: 8px;
    color: var(--ion-text-color, #000);
    font-size: 15px;
    font-weight: 600;
}

.m-l-7 {
    margin-left: 7px;
}

.time-setting {
    &::part(content) {
        height: 200px;
    }
}

.date-setting {
    &::part(content) {
        height: 350px;
    }
}

.cancellation_policy {
    &::part(content) {
        width: 90%;
        height: 460px;
        background: transparent;
        border-radius: 20px;
    }
}

.add-photo-modal {
    &::part(content) {
        height: 450px;
        width: 90%;
        border-radius: 20px;
    }

    ion-content {
        --background: #fff !important;
    }

    .add-item {
        .add-photo {
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: #f1f1f0;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                object-fit: cover;
                object-position: center;
                width: 100%;
                box-shadow: none !important;
                border-radius: 0 !important;
            }
        }

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 22px;
            z-index: 999;
        }

        .camera {
            font-size: 32px;
        }

        h3 {
            color: #1c1c1c;
            font-size: 20px;

            font-family: Montserrat-SemiBold !important;

        }
    }
}


.color-orange {
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.preview-img-modal {

    .blur {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }

    .close-icon {
        color: red;
        font-size: 40px;
        position: absolute;
        top: 30px;
        right: 10px;
        z-index: 9;
    }

    .preview-img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        /* blurring */
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
}

.success {
    color: var(--ion-color-success);
}

onboard-v2 .icon-container {
    align-items: inherit !important;
}


// PWA css start
@media screen and (max-width:767px) {
    .sm-d-none {
        display: none !important;
    }
}

@media screen and (min-width:768px) {

    app-login,
    app-forgotpassword,
    app-emailverified,
    app-incorrect-email,
    app-reset-password,
    app-signup,
    app-specialcode,
    app-home,
    app-you-can {

        .outline-button,
        .fill-button {
            max-width: 100% !important;

        }
    }

    img.logo_sec {
        width: 100%;
    }

    .outline-button,
    .fill-button {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .flex-container-form {
        display: flex;
    }

    .md-d-none {
        display: none !important;
    }

    .h-100vh {
        height: 100vh;
    }

    .md-content-center {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .w-full {
        width: 100%;
    }

    .flex-50 {
        flex: 0 0 50%;
        width: 50%;
    }

    .mlr-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .max-w-600 {
        max-width: 600px;
    }

    ion-modal {
        --max-width: 600px;

        .outline-button,
        .fill-button {
            max-width: 100% !important;

        }
    }

    ion-modal.my-custom-class {
        --max-width: 400px !important;
    }

    ion-header {
        ion-toolbar {
            --padding-start: 50px;
            --padding-end: 50px;
        }
    }
}

@media screen and (min-width:992px) {
    .lg-d-none {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .container-custom {
        width: 540px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .container-custom {
        width: 720px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 992px) {
    .container-custom {
        width: 960px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 1200px) {
    .container-custom {
        width: 1140px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.outline-button {
    --background-hover: var(--ion-color-primary);
    --color-hover: var(--ion-color-white);
    --background-activated: var(--ion-color-primary);
}

.fill-button {
    --background-hover: var(--ion-color-primary);
    --color-hover: var(--ion-color-white);

}

.cursor-pointer {
    cursor: pointer;
}

.add-photo-modal {
    --max-width: 600px;
}

// ion-menu{
//     display: none;
// }

@media screen and (min-width: 1200px) {
    input.cordova-camera-select {
        top: calc(100% - 398px);
        left: 30%;
        // &::after{
        //     content: "";
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     background-color: #000;

        // }
        // opacity: 0;
        // display: none;
    }

    div.cordova-camera-capture {
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        video {
            width: 600px;
            height: 600px;
        }

        button {
            padding: 10px;
            border-radius: 7px;
            position: absolute;
            top: 65%;
            font-size: 20px;
            background: #1acddb;
        }
    }
}

@media screen and (min-width:768px) and (max-width: 1199px) {
    input.cordova-camera-select {
        top: calc(100% - 308px);
        left: 30%;
        // opacity: 0;
    }

    div.cordova-camera-capture {
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        video {
            width: 600px;
            height: 600px;
        }

        button {
            padding: 10px;
            border-radius: 7px;
            position: absolute;
            top: 63%;
            font-size: 20px;
            background: #1acddb;
        }
    }
}

@media screen and (max-width:767px) {
    input.cordova-camera-select {
        top: calc(100% - 108px);
        left: 30%;

        // opacity: 0;
    }

    div.cordova-camera-capture {
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        video {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
        }

        button {
            top: unset !important;
            bottom: 10px !important;
            padding: 10px;
            border-radius: 7px;
            position: absolute;
            font-size: 20px;
            background: #1acddb;
        }
    }
}

.icon-style {
    cursor: pointer;
}

// app-edit-for-sale-listing,
// app-properties-for-rent-add-edit{
//     input.cordova-camera-select{
//         top: calc(100% - 461px);
//         left: 30%;
//         opacity: 0;
//     }

//     div.cordova-camera-capture{
//         height: 100vh;
//         width: 100vw;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         gap: 10px;
//         video{
//             width: 600px !important;
//             height: 600px !important;
//         }
//         button{
//             padding: 10px;
//             border-radius: 7px;
//             position: absolute;
//             top: 70%;
//             font-size: 20px;
//             background: #1acddb;
//         }
//     }  
// }





.p-color {
    color: #1acddb;
    font-weight: bold;
}

.stripe_field div {
    width: 100% !important;
}

.back_page {
    box-shadow: 0px 0px 10px #00000014;
    background: var(--ion-color-white);
    color: var(--ion-color-primary);
    border-radius: 6px;
    height: 32px;
    font-size: 25px;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 9999;
    cursor: pointer;
}